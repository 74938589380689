module.exports = [{
      plugin: require('/Users/sanders/code/sandersdenardi.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/sanders/code/sandersdenardi.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43803722-1"},
    },{
      plugin: require('/Users/sanders/code/sandersdenardi.com/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sanders/code/sandersdenardi.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
